import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import CalendarHourGrid from './hourGrid';

function formatDate(date) {
    return new Intl.DateTimeFormat('nb-NO', { year: 'numeric', month: 'long' }).format(date);
}

@observer
class CalendarInline extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
    }

    loadAll = async (props = this.props, skipDateReset) => {
        const { calendar = 'booking', startDate = new Date(), endDate } = props;
        const { userStore, calendarStore, calendarEventStore } = this.props.stores;
        const { calendarDate } = calendarStore;
        const { customer = {} } = userStore;

        if (!skipDateReset) {
            calendarStore.calendarSetDate(startDate);
        }
        // calendarStore.calendarSetDate(startDate);
        if (customer.calendarStartHour) {
            calendarStore.setStartHour(customer.calendarStartHour);
        }
        if (customer.calendarEndHour) {
            calendarStore.setEndHour(customer.calendarEndHour);
        }

        await calendarStore.load({
            query: {
                type: calendar,
            },
        });
        const calendarId = calendarStore.calendars.map(c => c.calendarId);
        await calendarEventStore.load({
            query: {
                calendarId,
                calendarDate: util.isoDate(calendarDate),
            },
            addData: ['recurringList', 'currentTime', 'currentTimeOffset', 'creators'],
        });
        calendarEventStore.isOverlapping();
        this.loadCalendarAvailability(props);
    }

    loadCalendarEvents = async (props = this.props) => {
        const { calendarStore, calendarEventStore } = this.props.stores;
        const { calendarDate } = calendarStore;
        const calendarId = calendarStore.calendars.map(c => c.calendarId);
        await calendarEventStore.load({
            query: {
                calendarId,
                calendarDate: util.isoDate(calendarDate),
            },
            addData: ['recurringList'],
        });
        calendarEventStore.isOverlapping();

        this.loadCalendarAvailability(props);
    }

    // loadCalendarAvailability = async (props = this.props) => {
    //     const { calendarAvailabilityStore } = this.props.stores;
    //     await calendarAvailabilityStore.load({ query: {}});
    // }
    loadCalendarAvailability = async (props = this.props) => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const daysInMonth = calendarStore.daysInMonth();
        // console.log({ daysInMonth })
        await calendarAvailabilityStore.load({
            query: {
                start: util.isoDate(daysInMonth[0].date, false, false, true),
                end: util.isoDate(daysInMonth[daysInMonth.length - 1].date, false, false, true),
            },
        });
    }

    addEventCallback = async (event, calendarHtmlId) => {
        this.loadAll(this.props, true);
    }

    clickHeader = e => {
        e.stopPropagation();
        e.preventDefault();
        const { calendarStore, appState, saleStore } = this.props.stores;
        const { calendarSelectDateTime, calendarId } = this.props;

        const { drawerLevel = 1, callback = () => {}, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('newCalendarAvailability', {
			height: drawerHeightLarge,
            callback: async () => {
                this.loadAll();
            },
		}, drawerLevel + 1);
    }

    handleScroll = e => {
        let isSticky = false;
        const div = e.target;
        if (div.scrollTop > 100) { // Adjust the threshold as needed
            isSticky = true;
        }
        this.setState({ isSticky });
    }

    componentDidMount() {
        this.loadAll();
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
    //         this.loadAll(nextProps);
    //     }
    // }

    componentWillUnmount() {
    }

    render() {
        const { isSticky } = this.state;
        const { calendarSelectDateTime, skipAnimal, inputKey, showPullDownHandle, pulldownHandleId, showViewEvent, enableSelection } = this.props;
        const { appState, userStore, calendarStore, calendarEventStore, calendarAvailabilityStore } = this.props.stores;
        const {
            colors,
            viewName,
            calendarDate,
            calendarDateStart,
            calendarDateEnd,
            isToday,
            isTodayWeek,
        } = calendarStore;
        const { currentTime, currentTimeOffset } = calendarEventStore;
        const { calendars } = calendarStore;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const localTime = util.isoDate(new Date());
        const localTimeOffset = new Date().getTimezoneOffset();
        const timeIsDifferent = localTime !== currentTime;

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center overflow-auto' onScroll={this.handleScroll}>
                <div class='w-100 h-100'>
                    <div class='d-flex flex-row w-100 justify-content-between mt-2'>
                        <div>
                            <button class={`btn btn-outline-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrev(this.loadCalendarEvents)}>
                                <i class='fa-duotone fa-caret-left' /> <Text id='calendar.prev'>Prev</Text>
                            </button>
                            <button class={`btn btn-${isToday ? 'primary' : 'outline-primary'} rounded-none`} onClick={() => calendarStore.calendarToday(this.loadCalendarEvents)}><Text id='calendar.today'>Today</Text></button>
                            <button class={`btn btn-outline-primary rounded-pill-right`} onClick={() => calendarStore.calendarNext(this.loadCalendarEvents)}>
                                <Text id='calendar.next'>Next</Text> <i class='fa-duotone fa-caret-right' />
                            </button>
                        </div>

                        {viewName === 'showMonth' ? <>
                            <div class='d-flex flex-column w-50'>

                                <div class='d-flex flex-row w-100 justify-content-center'>
                                    <button class={`btn btn-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrevMonth(this.loadCalendarEvents)}>
                                        <i class='fa-duotone fa-caret-left' />
                                    </button>
                                    <button class={`flex-fill btn btn-outline-primary rounded-none`} disabled>
                                        {formatDate(calendarDate)}
                                    </button>
                                    <button class={`btn btn-primary rounded-pill-right`} onClick={() => calendarStore.calendarNextMonth(this.loadCalendarEvents)}>
                                        <i class='fa-duotone fa-caret-right' />
                                    </button>
                                </div>

                                <div class='table-responsive mt-1'>
                                    <table class='table table-sm table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th class='text-center'><Text id='calendar.week'>Week</Text></th>
                                                <th class='text-center'><Text id='calendar.monday-short'>M</Text></th>
                                                <th class='text-center'><Text id='calendar.tuesday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.wednesday-short'>W</Text></th>
                                                <th class='text-center'><Text id='calendar.thursday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.friday-short'>F</Text></th>
                                                <th class='text-center'><Text id='calendar.saturday-short'>S</Text></th>
                                                <th class='text-center'><Text id='calendar.sunday-short'>S</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {calendarStore.weeksInMonth().map((obj, idx) => {
                                                if (!obj.week) {
                                                    return '';
                                                }
                                                return (<>
                                                    <tr>
                                                        <td class='text-center font-weight-lighter'>
                                                            {obj.week?.week}
                                                        </td>
                                                        {obj.week?.daysInWeek.map((day, idx) => {
                                                            const dayOfWeek = day.getDay();
                                                            const isToday = day.toDateString() === new Date().toDateString();
                                                            const currentIsToday = calendarDate.toDateString() === new Date().toDateString();
                                                            const isCurrentDay = day.toDateString() === calendarDate.toDateString() && !currentIsToday;
                                                            return (<>
                                                                <td
                                                                    class={`text-center
                                                                        ${(dayOfWeek === 6 || dayOfWeek === 0) ? 'text-danger' : ''}
                                                                        ${isToday ? 'font-weight-bolder' : ''}
                                                                        ${isCurrentDay ? 'bg-primary text-white' : ''}
                                                                    `}
                                                                    style={`
                                                                        ${isToday ? 'font-weight: 1000 !important;' : ''}
                                                                    `}
                                                                    onClick={() => calendarStore.calendarSetDate(day, this.loadCalendarEvents)}
                                                                >
                                                                    {day.getDate()}
                                                                </td>
                                                            </>);
                                                        })}
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </> : <>
                            <div class='d-flex flex-column align-items-end font-weight-lighter'>
                                <div><small class={`${timeIsDifferent ? 'text-danger' : 'text-muted'}`}>PC: {localTime} ({localTimeOffset})</small></div>
                                <div><small class='text-muted'>Server: {currentTime} ({currentTimeOffset})</small></div>
                            </div>
                        </>}
                        <div>
                            <button
                                type='button'
                                class={`btn btn-${viewName === 'showMonth' ? 'primary' : 'outline-primary'} rounded-pill-both`}
                                onClick={() => calendarStore.calendarChangeView('showMonth')}>
                                    {viewName === 'showMonth' ? <>
                                        <Text id='calendar.hideMonth'>Hide month</Text>
                                    </> : <>
                                        <Text id='calendar.showMonth'>Show month</Text>
                                    </>}
                            </button>
                            <button
                                type='button'
                                class={'btn btn-lg btn-link'}
                                onClick={this.clickHeader}
                            >
                                <i class='fa-regular fa-gear' />
                            </button>
                        </div>
                    </div>

                    <div class={`d-flex flex-row w-100 justify-content-start mt-2 px-2 box-header font-weight-lighter text-muted`}>
                        {calendarDate && <>
                            <div class='d-flex flex-row w-100 align-items-center justify-content-between'>
                                {/* {viewName === 'month' && <>
                                    <span class='mr-2'>{util.formatDate(calendarDate, { locale: 'nb-NO' })}</span>
                                </>}
                                {viewName === 'week' && <>
                                    <span class='mr-2'>{util.formatDate(calendarDate, { locale: 'nb-NO' })}</span>
                                    <span class='mr-2'>w{util.getWeek(calendarDate, { locale: 'nb-NO' })}</span>
                                </>} */}
                                {/* {viewName === 'day' && <> */}
                                    <span class='mr-2'>{util.formatDate(calendarDate, {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        locale: 'nb-NO',
                                        // hour: '2-digit',
                                        // minute: '2-digit',
                                        // hour12: false,
                                    })}</span>
                                    <span class='mr-2'>w{util.getWeek(calendarDate)}</span>
                                {/* </>} */}
                            </div>
                        </>}
                    </div>
                    <div class='d-flex flex-row w-100 justify-content-between mt-0 mb-5'>
                        {calendars.map((c, idx) => {
                            const color = c.color ? c.color : colors[idx].color;
                            const backgroundColor = c.backgroundColor ? c.backgroundColor : colors[idx].backgroundColor;
                            const { startHour, startMinute, endHour, endMinute } = calendarAvailabilityStore.getAvailableHours(calendarDate, c.id);

                            return (<>
                                <div class='d-flex flex-column w-100 h-100 mt-2 mx-1 px-1'>
                                    <div
                                        class='rounded-pill my-1 px-2 py-1 flex-fill text-center calendar-header'
                                        style={`
                                            background-color: ${backgroundColor};
                                            color: ${color};
                                            // ${isSticky ? `
                                            //     position: fixed;
                                            //     top: 100;
                                            //     left: 0;
                                            //     z-index: 100;
                                            // ` : ''}
                                        `}
                                    >
                                        <small>
                                            {c.name}
                                            {startHour && endHour && <>
                                                <span class='ml-2 font-weight-lighter'>({util.padDate(startHour)}:{util.padDate(startMinute)}-{util.padDate(endHour)}:{util.padDate(endMinute)})</span>
                                            </>}
                                        </small>
                                    </div>
                                    <div class={`w-100 px-2 py-1 mt-2  ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                        <CalendarHourGrid
                                            key={`calendar-${c.id}-${inputKey}`}
                                            stores={this.props.stores}
                                            idx={idx}
                                            calendarDate={calendarDate}
                                            calendarId={c.calendarId}
                                            calendarSelectDateTime={calendarSelectDateTime}
                                            callback={this.addEventCallback}
                                            skipAnimal={skipAnimal}

                                            useCalendarAvailability={c.useCalendarAvailability}
                                            availabilityHoursStart={startHour}
                                            availabilityMinutesStart={startMinute}
                                            availabilityHoursEnd={endHour}
                                            availabilityMinutesEnd={endMinute}
                                            showPullDownHandle={showPullDownHandle}
                                            pulldownHandleId={pulldownHandleId}
                                            showViewEvent={showViewEvent}
                                            enableSelection={enableSelection}
                                        />
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>

                </div>
            </div>
        </>);
    }
}

export default CalendarInline;
