import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class SalePayment extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            payDate: util.isoDate(new Date(), true),
        };
    }

    changePayDate = async e => {
        const { value: payDate } = e.target;
        this.setState({ payDate });
    }

    savePayment = async (e) => {
        const { partialAmount, payDate = new Date() } = this.state;
        this.setState({ paymentInProgress: true });
        const { method } = e.target.closest('button').dataset;
        const { callback = () => {} } = this.props;
        const { saleStore, salePaymentStore } = this.props.stores;
        const { newSale } = saleStore;
        if (partialAmount > 0) {
            // saveField(id, field, value, updateMemory, opt = {}) {
            // await saleStore.saveField(newSale.id, 'paymentAmount', 'vipps', true);
            // await saleStore.saveField(newSale.id, 'paymentAmount', 'vipps', true);
            // await saleStore.save({
            //     id: newSale.id,
            //     paymentAmount: partialAmount,
            //     paymentMethod: method,
            // });

            // Save salePayment
            await salePaymentStore.insert({
                sale: newSale.id,
                amount: method === 'creditNote' ? 0 - partialAmount : partialAmount,
                vat: 0,
                paymentMethod: method,
                paidDate: payDate,
            });

            const partialPayments = newSale.partialPayments || [];
            saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'partial');
            saleStore.updateObjectKeyValue('newSale', 'paymentAmount', parseInt(partialAmount, 10));
            saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('partial'));
            saleStore.updateObjectKeyValue('newSale', 'partialPayments', [...partialPayments, {
                amount: parseInt(partialAmount, 10),
                vat: 0,
                date: payDate,
                paymentMethod: method,
            }]);
        } else {
            const totalAmount = saleStore.sumTotal(newSale.products, false);
            // Save salePayment
            await salePaymentStore.insert({
                sale: newSale.id,
                amount: method === 'creditNote' ? 0 - totalAmount : totalAmount,
                vat: 0,
                paymentMethod: method,
                paidDate: payDate,
            });

            if (newSale.paymentMethod !== 'invoice') {
                saleStore.updateObjectKeyValue('newSale', 'paymentMethod', method);
            }
            if (method === 'cash' || method === 'card' || method === 'vipps' || method === 'account') {
                // TODO: Add partial payment support
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('paid'));
                saleStore.updateObjectKeyValue('newSale', 'paidDate', payDate);
            } else if (method === 'invoice') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoice'));
            } else if (method === 'account') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('account'));
            } else if (method === 'creditNote') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('creditNote'));
            }
        }

        if (newSale.paymentMethod === 'invoice') {
            await saleStore.saveSale({ calledFrom: 'salePayment.savePayment', event: e, fieldsToSave: ['paymentAmount', 'status', 'paidDate'] });
        } else {
            await saleStore.saveSale({ calledFrom: 'salePayment.savePayment', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        }

        this.setState({ paymentInProgress: false });
        callback(true);
    }

    saveInvoice = async (e) => {
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'invoice');
        saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoice'));

        await saleStore.saveSale({ calledFrom: 'salePayment.saveInvoice', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });
        callback(true);
    }

    createCreditNote = async (e) => {
        // 1. Mark current sale as invoice
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'invoiceCredited');
        saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoiceCredited'));

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNote', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });

        // 2. Create new sale with negative amount and status creditNote
        const oldSale = { ...newSale };
        delete oldSale.id;
        delete oldSale.createdDate;
        delete oldSale.saleDate;
        oldSale.products = oldSale.products.map(e => {
            const p = { ...e };
            p.discountAmount = 0 - p.discountAmount;
            p.price = 0 - p.price;
            return p;
        });

        saleStore.createNewSale({ user: oldSale.user });
        saleStore.updateKeyValue('newSale', {
            ...oldSale,
            paymentMethod: 'creditNote',
            status: saleStore.getSaleStatus('creditNote'),
        });

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNote', event: e });
        callback(true);
    }

    createCreditNoteSale = async (e) => {
        // 1. Mark current sale as invoice
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'saleCredited');
        // saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('saleCredited'));

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNoteSale', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });

        // 2. Create new sale with negative amount and status creditNote
        const oldSale = { ...newSale };
        delete oldSale.id;
        delete oldSale.createdDate;
        delete oldSale.saleDate;
        oldSale.products = oldSale.products.map(e => {
            const p = { ...e };
            p.discountAmount = 0 - p.discountAmount;
            p.price = 0 - p.price;
            return p;
        });

        saleStore.createNewSale({ user: oldSale.user });
        saleStore.updateKeyValue('newSale', {
            ...oldSale,
            paymentMethod: 'creditNote',
            status: 0,
        });

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNoteSale', event: e });
        callback(true);
    }

    unlockPaymentMethod = async (e) => {
        const { saleStore } = this.props.stores;
        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', null);
        saleStore.updateObjectKeyValue('newSale', 'paidDate', null);
        saleStore.updateObjectKeyValue('newSale', 'paymentAmount', 0);
        saleStore.updateObjectKeyValue('newSale', 'status', 0);
        await saleStore.saveSale({ calledFrom: 'salePayment.unlockPaymentMethod', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
    }

    togglePartialPayment = e => {
        this.setState({ partialPayment: !this.state.partialPayment });
    }

    toggleCashPayment = e => {
        this.setState({ cashPayment: !this.state.cashPayment });
    }

    clearPaidAmount = e => {
        this.setState({ paidAmount: 0 });
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    onClickDeleteSalePayment = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!confirm('Are you sure you want to delete this salePayment?')) {
            return;
        }

        const { id } = e.target.closest('tr').dataset;
        const { salePaymentStore, saleStore } = this.props.stores;
        // console.log('onClickDeleteSalePayment', id, parseInt(id, 10));
        const { newSale } = saleStore;
        await salePaymentStore.delete(parseInt(id, 10));

        setTimeout(() => {
            if (newSale?.id) {
                this.loadSalePayments(newSale.id);
            }
        }, 1000);
    }

    componentDidMount() {
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        this.loadSalePayments(newSale.id);
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections, partialPayment, paidAmount, payDate, paymentInProgress, cashPayment } = this.state;
        const { userStore, saleStore } = this.props.stores;
        const { customer, user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            currentPayments = [],
        } = saleStore;

        const saleIsLocked = !!newSale.paymentMethod;
        // const saleIsLocked = diff?.days > 360 || newSale.status > 0;
        const restPayment = saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, false), false, false);

        const { featureFlags = {} } = customer;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {newSale.paymentMethod === 'invoice' ? <>
                            <Text id='dyrejournal.invoice-payment'>Invoice payment</Text>
                        </> : <>
                            <Text id='dyrejournal.sale-payment'>Sale Payment</Text>
                        </>}
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <div class='d-flex flex-column mt-0 pt-2 pr-5' style='font-size: 1.5em;'>
                            <div class='d-flex flex-row justify-content-end'>
                                <div><Text id='dyrejournal.payment-total'>Total</Text></div>
                                <div class='ml-3 text-success font-weight-bold'>{util.format(saleTotal, 2, ',', ' ')}</div>
                            </div>
                            <div class='d-flex flex-row justify-content-end font-weight-lighter'>
                                <div><Text id='dyrejournal.payment-tax'>Tax</Text></div>
                                <div class='ml-3'>{saleTax}</div>
                            </div>
                            <div class='d-flex flex-row justify-content-end font-weight-bold text-danger'>
                                <div><Text id='dyrejournal.payment-rest'>Rest payment</Text></div>
                                <div class='ml-3'>{util.format(restPayment, 2)}</div>
                            </div>
                        </div>

                        {!newSale.id ? <>
                            <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                <div class='spinner-grow text-secondary' role='status'>
                                    <span class='sr-only'>Loading...</span>
                                </div>
                            </div>
                        </> : <>
                            {Math.abs(restPayment) > 0 && <>
                                <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                    <div class='input-group w-50'>
                                        <div class='input-group-prepend'>
                                            <div class='input-group-text rounded-pill-left'>
                                                <Text id='dyrejournal.payment-date'>Payment date</Text>
                                            </div>
                                        </div>
                                        <input
                                            class='form-control inputForm rounded-pill-right'
                                            type='datetime-local'
                                            onInput={this.changePayDate}
                                            value={payDate}
                                        />
                                    </div>
                                </div>

                                {partialPayment ? <>
                                    <div class='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
                                    <div class='alert alert-primary rounded-lg' role='alert'>
                                        <h5><Text id='dyrejournal.partial-payment'>Partial Payment</Text></h5>
                                        <p><Text id='dyrejournal.partial-payment-help'>Enter payment amount and choose partial payment method below.</Text></p>
                                    </div>
                                        <div class='input-group w-50'>
                                            <div class='input-group-prepend'>
                                                <div class='input-group-text rounded-pill-left'>
                                                    <Text id='dyrejournal.partial-payment'>Partial Payment</Text>
                                                </div>
                                            </div>
                                            <input
                                                type='text'
                                                class='form-control inputForm'
                                                placeholder='Beløp'
                                                min={0}
                                                max={saleTotal}
                                                onInput={linkstate(this, 'partialAmount')}
                                            />
                                            <div class='input-group-append'>
                                                <button type='button' class='btn btn-danger' onClick={this.togglePartialPayment}>
                                                    <i class='fa-solid fa-times' />
                                                </button>
                                            </div>
                                            <div class='input-group-append'>
                                                <div class='input-group-text rounded-pill-right'><Text id='dyrejournal.currency-nok'>NOK</Text></div>
                                            </div>
                                            <small class='form-text text-muted px-2 pt-1'><Text id='dyrejournal.partial-payment-rest'>Rest amount</Text>: {util.format(restPayment, 2)}</small>
                                        </div>
                                        <small class='form-text text-muted mt-2'><Text id='dyrejournal.partial-payment-mark'>Choose partial payment method below.</Text></small>
                                    </div>
                                </> : <>
                                    {cashPayment ? <>
                                        <div class='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
                                            <div class='input-group w-50'>
                                                <div class='input-group-prepend'>
                                                    <div class='input-group-text rounded-pill-left'>
                                                        <Text id='dyrejournal.cash-amount'>Cash amount</Text>
                                                    </div>
                                                </div>
                                                <input
                                                    type='text'
                                                    class={`form-control inputForm ${paidAmount ? '' : 'rounded-pill-right'}`}
                                                    min={0}
                                                    onInput={linkstate(this, 'paidAmount')}
                                                    value={paidAmount}
                                                />
                                                {paidAmount > 0 && <div class='input-group-append '>
                                                    <button type='button' class='btn btn-danger rounded-pill-right' onClick={this.clearPaidAmount}>
                                                        <i class='fa-solid fa-times' />
                                                    </button>
                                                </div>}
                                            </div>
                                        </div>
                                        {paidAmount > 0 && <div class='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
                                            <div class='input-group w-50'>
                                                <div class='input-group-prepend'>
                                                    <div class='input-group-text rounded-pill-left'><Text id='dyrejournal.money-bak'>Money back</Text></div>
                                                </div>
                                                <input
                                                    disabled={true}
                                                    type='text'
                                                    class='form-control inputForm rounded-pill-right'
                                                    value={paidAmount - saleTotal}
                                                />
                                            </div>
                                        </div>}
                                    </> : <>
                                        <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                            <button type='button' class='btn btn-link' onClick={this.toggleCashPayment}>
                                                <i class='fa-duotone fa-money-bill' /> <Text id='dyrejournal.sale-pay-with-cash'>Show Cash Calculator</Text>
                                            </button>
                                        </div>
                                    </>}
                                </>}

                                <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                    {!featureFlags.paymentHideCash && <>
                                        <button type='button' class='btn btn-lg btn-success rounded-pill' disabled={paymentInProgress} onClick={this.savePayment} data-method='cash'>
                                            {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('cash')} /> <Text id='dyrejournal.sale-payment-cash'>Cash</Text>
                                        </button>
                                    </>}
                                    <button type='button' class='btn btn-primary rounded-pill ml-3' disabled={paymentInProgress} onClick={this.savePayment} data-method='card'>
                                        {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('card')} /> <Text id='dyrejournal.sale-payment-card'>Card</Text>
                                    </button>
                                    {!featureFlags.paymentHideVipps && <>
                                        <button type='button' class='btn btn-orange rounded-pill ml-3' disabled={paymentInProgress} onClick={this.savePayment} data-method='vipps'>
                                            {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('vipps')} /> <Text id='dyrejournal.sale-payment-vipps'>Vipps</Text>
                                        </button>
                                    </>}
                                    {!featureFlags.paymentHideAccount && <>
                                        <button type='button' class='btn btn-danger rounded-pill ml-3' disabled={paymentInProgress} onClick={this.savePayment} data-method='account'>
                                            {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('account')} /> <Text id='dyrejournal.sale-payment-account'>Account</Text>
                                        </button>
                                    </>}
                                </div>
                                <div class='w-100 d-flex flex-row justify-content-center mt-3 pb-3 border-bottom'>
                                    {partialPayment ? <>
                                    </> : <>
                                        <button type='button' class='btn btn-outline-secondary rounded-pill' onClick={this.togglePartialPayment}>
                                            <i class={saleStore.getPaymentMethodIcon('partial')} /> <Text id='dyrejournal.sale-payment-partial'>Partial</Text>
                                        </button>
                                    </>}
                                </div>
                            </>}

                            {saleIsLocked ? <>
                                <div class='w-100 d-flex flex-column align-items-center mt-5'>
                                    <div>
                                        <Text id='dyrejournal.sale-payment-method-already-choosen'>Payment method for sale already set.</Text>  (<i class={`${saleStore.getPaymentMethodIcon(newSale.paymentMethod)} mx-1`} /> {saleStore.getPaymentMethodText(newSale.paymentMethod)})
                                    </div>
                                    <button type='button' class='btn btn-link  rounded-pill ml-3' onClick={this.unlockPaymentMethod}>
                                        <i class='fa-solid fa-lock' /> <Text id='dyrejournal.sale-unlock-payment-method'>Unlock payment method</Text>
                                    </button>
                                </div>
                                {/* Add invoice payment methods.<br />
                                restPayment: {util.format(restPayment, 2)}<br />
                                newSale.paymentMethod: {newSale.paymentMethod}<br /> */}
                                {/* <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                    <button type='button' class='btn btn-info rounded-pill ml-3' disabled={paymentInProgress} onClick={this.createCreditNoteSale} data-method='creditNote'>
                                        {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('creditNote')} /> <Text id='dyrejournal.sale-create-creditNote'>Create Credit Note</Text>
                                    </button>
                                </div> */}
                            </> : <>
                            </>}

                            {currentPayments && currentPayments.length > 0 && <>
                                <div class='font-weight-lighter px-3 box-header mt-5'>
                                    <Text id='dyrejournal.existing-sale-payments'>Existing Payments</Text>
                                </div>
                                <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                                    <div class='w-100 d-flex flex-column'>
                                        <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                            <small>
                                            <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                                <table class='table table-striped table-sm mb-0'>
                                                    <thead>
                                                        <tr>
                                                            <th class='text-muted text-left'><nobr><Text id='dyrejournal.partial-payment-date'>Date</Text></nobr></th>
                                                            <th class='text-muted text-center'><nobr><Text id='dyrejournal.partial-payment-method'>Method</Text></nobr></th>
                                                            <th class='text-muted text-right'><nobr><Text id='dyrejournal.sale.amount'>Amount</Text></nobr></th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPayments.map((e, idx) => <>
                                                            <tr data-id={e.id}>
                                                                <td>{util.formatDate(e.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</td>
                                                                <td class='text-center'><i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {e.paymentMethod}</td>
                                                                <td class='text-right'>{util.format(e.amount, 2, ',', ' ')}</td>
                                                                <td class='d-none d-sm-table-cell' onClick={this.onClickDeleteSalePayment} data-id={e.id}>
                                                                    <i class='fa-solid fa-trash text-danger' />
                                                                </td>
                                                            </tr>
                                                        </>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </small>
                                            {/* <xmp>{JSON.stringify(currentPayments, null, 4)}</xmp> */}
                                        </div>
                                    </div>
                                </div>
                            </>}

                        </>}

                    </div>
                </div>
            </div>
        </>);
    }
}

export default SalePayment;
