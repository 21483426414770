import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

function createYearRange(start, end) {
    const yearRange = [];
    for (let year = start; year <= end; year++) {
        yearRange.push(year);
    }
    return yearRange;
}

function createMonthRange() {
    const monthRange = [];
    for (let month = 1; month <= 12; month++) {
        monthRange.push(month);
    }
    return monthRange;
}

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class CalendarStore extends LocalModel {
    constructor() {
        super('calendar', {
            namePlural: 'calendars',
            namePluralReal: 'calendars',
            sort: 'sort',
            limit: 100,
            api: {
                search: {
                    url: '/api/calendars/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/calendars/',
                    params: {},
                },
                save: {
                    url: '/api/calendars/',
                    params: {},
                },
                delete: {
                    url: '/api/calendars/',
                    params: {},
                },
            },
        });
    }

    @observable newCalendar = {};

    @observable calendar = {};

    @observable calendars = [];

    @observable availabilityCalendars = [];

    @observable clientHours = [];

    @observable totalHours = [];

    @observable colors = [
        {
            color: '#202020',
            backgroundColor: '#0ab3e3',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#acdcd2',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#5dc3b3',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#fbac19',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#f36e21',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#f27684',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#4CAF50',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#2196F3',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#c185cc',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#E91E63',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#FFC107',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#FF5722',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#CDDC39',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#607D8B',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#795548',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        },
        {
            color: '#202020',
            backgroundColor: '#3F51B5',
            dragBackgroundColor: '#c0c0c0',
            borderColor: '#808080',
        }
    ];

    @observable viewName = 'day';

    @observable calendarHours = [];

    @observable calendarDate = new Date();

    @observable calendarDateStart = new Date();

    @observable calendarDateEnd = new Date();

    @observable workhourStart = 8;

    @observable workhourEnd = 16;

    @observable startHour = 6;

    @observable endHour = 23;

    @observable isToday = true;

    @observable isTodayWeek = true;

    @observable dragging = false;

    @observable dragging = false;

    @observable startY = 0;

    @observable startYAbs = 0;

    @observable endY = 0;

    @observable selectionStartDate = null;

    @observable selectionEndDate = null;

    @observable selectionCalendarId = null;

    @observable eventId = null;

    @observable eventEnd = null;

    @observable yearRange = createYearRange(1900, 2100);

    @observable monthRange = createMonthRange();

    @computed
    get currentMonth() {
        return this.calendarDate.getMonth() + 1;
    }

    @computed
    get currentYear() {
        return this.calendarDate.getFullYear();
    }

    @action
    setStartHour(value) {
        this.startHour = value;
    }

    @action
    setEndHour(value) {
        this.endHour = value;
    }

    @action
    setDragging(value) {
        this.dragging = value;
    }

    @action
    setStartY(value) {
        this.startY = value;
    }

    @action
    setStartYAbs(value) {
        this.startYAbs = value;
    }

    @action
    setEndY(value) {
        this.endY = value;
    }

    @action
    resetDragging() {
        this.dragging = false;
        this.startY = 0;
        this.startYAbs = 0;
        this.endY = 0;
        this.selectionStartDate = null;
        this.selectionEndDate = null;
    }

    @action
    checkToday() {
        const yyyy = this.calendarDate.getFullYear();
        const mm = this.calendarDate.getMonth() + 1;
        const dd = this.calendarDate.getDate();
        const today = new Date();
        const todayYyyy = today.getFullYear();
        const todayMm = today.getMonth() + 1;
        const todayDd = today.getDate();
        this.isToday = yyyy === todayYyyy && mm === todayMm && dd === todayDd;
    }

    @action
    checkTodayWeek() {
        this.isTodayWeek = this.calendarDate.toDateString() === new Date().toDateString();
    }

    @action
    calendarPrev(callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setDate(date.getDate() - 1);
        this.calendarDate = date;
        this.checkToday();
        callback();
    }

    @action
    calendarNext(callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setDate(date.getDate() + 1);
        this.calendarDate = date;
        this.checkToday();
        callback();
    }

    @action
    calendarToday(callback = () => {}) {
        this.calendarDate = new Date();
        this.checkToday();
        callback();
    }

    @action
    calendarSetDate(date, callback = () => {}) {
        this.calendarDate = new Date(date);
        this.checkToday();
        callback();
    }

    @action
    calendarSetYear(year, callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setFullYear(year);
        this.calendarDate = date;
        callback();
    }

    @action
    calendarSetMonth(month, callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setMonth(month);
        this.calendarDate = date;
        callback();
    }

    @action
    calendarNextMonth(callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setMonth(date.getMonth() + 1);
        this.calendarDate = date;
        callback();
    }

    @action
    calendarPrevMonth(callback = () => {}) {
        const date = new Date(this.calendarDate);
        date.setMonth(date.getMonth() - 1);
        this.calendarDate = date;
        callback();
    }

    daysInMonth() {
        const date = new Date(this.calendarDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        // return new Date(year, month + 1, 0).getDate();
        // return all days with date and day of week
        const days = [];
        const lastDay = new Date(year, month + 1, 0);
        const numDays = lastDay.getDate();
        for (let i = 1; i <= numDays; i += 1) {
            const day = new Date(year, month, i);
            days.push({
                day: day.getDate(),
                dayOfWeek: day.getDay(),
                nameOfDay: util.formatDate(day, { weekday: 'long', locale: 'nb-NO' }),
                date: day,
            });
        }
        return days;
    }

    weeksInMonth() {
        // Return all weeks in month
        const date = new Date(this.calendarDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const weeksInMonth = util.getWeeksInMonth(month, year, 'monday');
        const weeks = [];
        for (let i = 0, l = weeksInMonth.length ; i <= l; i += 1) {
            const week = weeksInMonth[i];
            weeks.push({
                month,
                week,
            });
        }
        return weeks;
    }

    @action
    calendarChangeView(viewName) {
        if (viewName === this.viewName) {
            this.viewName = 'day';
        } else {
            this.viewName = viewName;
        }
    }

    @action
    getHoursInDay(date = this.calendarDate) {
        const hours = [];
        for (let i = this.startHour; i < this.endHour; i += 1) {
            const hour = new Date(date);
            hour.setHours(i);
            hour.setMinutes(0);
            hour.setSeconds(0);
            hour.setMilliseconds(0);
            hours.push(hour);
        }
        this.calendarHours = hours;
    }

    @action
    setSelectionStartDate(date) {
        this.selectionStartDate = date;
    }

    @action
    setSelectionEndDate(date) {
        this.selectionEndDate = date;
    }

    @action
    setSelectionCalendarId(calendarId) {
        this.selectionCalendarId = calendarId;
    }

    @action
    setEventId(eventId) {
        this.eventId = eventId;
    }

    @action
    setEndOfEvent(eventEnd) {
        this.eventEnd = eventEnd;
    }

    getSelectionEvent(date, calendarId) {
        const start = new Date(date);
        const end = new Date(date);
        // Set end to 59 minutes and 59 seconds
        end.setMinutes(end.getMinutes() + 59);
        end.setSeconds(end.getSeconds() + 59);

        if (this.selectionStartDate >= start && this.selectionStartDate <= end && this.selectionCalendarId === calendarId) {
            return {
                start: this.selectionStartDate,
                end: this.selectionEndDate,
                calendarId,
            };
        }
        return null;
    }

    getDayName(dayOfWeek) {
        return DAYS[dayOfWeek];
    }
}

const store = new CalendarStore();
export default store;

// Named export for the class itself
export { CalendarStore };
