import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

import DyrejournalSaleReceipt from '../../components/dyrejournal/saleReceipt';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class SalePaymentInvoice extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            payDate: util.isoDate(new Date(), true),
        };
    }

    changePayDate = async e => {
        const { value: payDate } = e.target;
        this.setState({ payDate });
    }

    savePayment = async (e) => {
        const { partialAmount, payDate = new Date() } = this.state;
        this.setState({ paymentInProgress: true });
        const { method } = e.target.closest('button').dataset;
        const { callback = () => {} } = this.props;
        const { saleStore, salePaymentStore } = this.props.stores;
        const { newSale } = saleStore;
        if (partialAmount > 0) {
            // saveField(id, field, value, updateMemory, opt = {}) {
            // await saleStore.saveField(newSale.id, 'paymentAmount', 'vipps', true);
            // await saleStore.saveField(newSale.id, 'paymentAmount', 'vipps', true);
            // await saleStore.save({
            //     id: newSale.id,
            //     paymentAmount: partialAmount,
            //     paymentMethod: method,
            // });

            // Save salePayment
            await salePaymentStore.insert({
                sale: newSale.id,
                amount: method === 'creditNote' ? 0 - partialAmount : partialAmount,
                vat: 0,
                paymentMethod: method,
                paidDate: payDate,
            });

            const partialPayments = newSale.partialPayments || [];
            saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'partial');
            saleStore.updateObjectKeyValue('newSale', 'paymentAmount', parseInt(partialAmount, 10));
            saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('partial'));
            saleStore.updateObjectKeyValue('newSale', 'partialPayments', [...partialPayments, {
                amount: parseInt(partialAmount, 10),
                vat: 0,
                date: payDate,
                paymentMethod: method,
            }]);
        } else {
            const totalAmount = saleStore.sumTotal(newSale.products, false);
            // Save salePayment
            await salePaymentStore.insert({
                sale: newSale.id,
                amount: method === 'creditNote' ? 0 - totalAmount : totalAmount,
                vat: 0,
                paymentMethod: method,
                paidDate: payDate,
            });

            saleStore.updateObjectKeyValue('newSale', 'paymentMethod', method);
            if (method === 'cash' || method === 'card' || method === 'vipps' || method === 'account') {
                // TODO: Add partial payment support
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('paid'));
                saleStore.updateObjectKeyValue('newSale', 'paidDate', payDate);
            } else if (method === 'invoice') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoice'));
            } else if (method === 'account') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('account'));
            } else if (method === 'creditNote') {
                saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('creditNote'));
            }
        }
        await saleStore.saveSale({ calledFrom: 'salePayment.savePayment', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });
        callback(true);
    }

    saveInvoice = async (e) => {
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'invoice');
        saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoice'));

        await saleStore.saveSale({ calledFrom: 'salePayment.saveInvoice', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });
        await this.sendInvoice();
        callback(true);
    }

    sendInvoice = async e => {
        const { drawerLevel = 1 } = this.props;
        // console.log('onClickEmailReminder', e);
		const { appState, saleStore } = this.props.stores;
        const { newSale } = saleStore;
        this.setState({
            emailSaleSending: true,
            emailSaleSendStatus: null,
        });

        // const response = await saleStore.emailReminder({ sale: newSale });
		const { drawerHeightMedium } = appState;
        appState.openDrawer('sendInvoice', {
			height: drawerHeightMedium,
            newSale,
            callback: (response) => {
                this.setState({
                    emailSaleSending: false,
                    emailSaleSendStatus: response?.status,
                });
                clearTimeout(this.emailTimer);

                this.emailTimer = setTimeout(() => {
                    // console.log('emailTimer');
                    this.setState({
                        emailSaleSending: false,
                        emailSaleSendStatus: null,
                    });
                }, 2000);
            }
		}, drawerLevel + 1);
    }

    createCreditNote = async (e) => {
        // 1. Mark current sale as invoice
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'invoiceCredited');
        saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('invoiceCredited'));

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNote', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });

        // 2. Create new sale with negative amount and status creditNote
        const oldSale = { ...newSale };
        delete oldSale.id;
        delete oldSale.createdDate;
        delete oldSale.saleDate;
        oldSale.products = oldSale.products.map(e => {
            const p = { ...e };
            p.discountAmount = 0 - p.discountAmount;
            p.price = 0 - p.price;
            return p;
        });

        saleStore.createNewSale({ user: oldSale.user });
        saleStore.updateKeyValue('newSale', {
            ...oldSale,
            paymentMethod: 'creditNote',
            status: saleStore.getSaleStatus('creditNote'),
        });

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNote', event: e });
        callback(true);
    }

    createCreditNoteSale = async (e) => {
        // 1. Mark current sale as invoice
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', 'saleCredited');
        // saleStore.updateObjectKeyValue('newSale', 'status', saleStore.getSaleStatus('saleCredited'));

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNoteSale', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
        this.setState({ paymentInProgress: false });

        // 2. Create new sale with negative amount and status creditNote
        const oldSale = { ...newSale };
        delete oldSale.id;
        delete oldSale.createdDate;
        delete oldSale.saleDate;
        oldSale.products = oldSale.products.map(e => {
            const p = { ...e };
            p.discountAmount = 0 - p.discountAmount;
            p.price = 0 - p.price;
            return p;
        });

        saleStore.createNewSale({ user: oldSale.user });
        saleStore.updateKeyValue('newSale', {
            ...oldSale,
            paymentMethod: 'creditNote',
            status: 0,
        });

        await saleStore.saveSale({ calledFrom: 'salePayment.createCreditNoteSale', event: e });
        callback(true);
    }

    unlockPaymentMethod = async (e) => {
        const { saleStore } = this.props.stores;
        saleStore.updateObjectKeyValue('newSale', 'paymentMethod', null);
        saleStore.updateObjectKeyValue('newSale', 'paidDate', null);
        saleStore.updateObjectKeyValue('newSale', 'paymentAmount', 0);
        saleStore.updateObjectKeyValue('newSale', 'status', 0);
        await saleStore.saveSale({ calledFrom: 'salePayment.unlockPaymentMethod', event: e, fieldsToSave: ['paymentMethod', 'paymentAmount', 'status', 'paidDate'] });
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    onClickDeleteSalePayment = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!confirm('Are you sure you want to delete this salePayment?')) {
            return;
        }

        const { id } = e.target.closest('tr').dataset;
        const { salePaymentStore, saleStore } = this.props.stores;
        // console.log('onClickDeleteSalePayment', id, parseInt(id, 10));
        const { newSale } = saleStore;
        await salePaymentStore.delete(parseInt(id, 10));

        setTimeout(() => {
            if (newSale?.id) {
                this.loadSalePayments(newSale.id);
            }
        }, 1000);
    }

    onClickEmailReminder = async e => {
        const { drawerLevel = 1 } = this.props;
        // console.log('onClickEmailReminder', e);
		const { appState, saleStore } = this.props.stores;
        const { newSale } = saleStore;
        this.setState({
            emailSaleSending: true,
            emailSaleSendStatus: null,
        });

        // const response = await saleStore.emailReminder({ sale: newSale });
		const { drawerHeightMedium } = appState;
        appState.openDrawer('sendInvoice', {
			height: drawerHeightMedium,
            newSale,
            callback: (response) => {
                this.setState({
                    emailSaleSending: false,
                    emailSaleSendStatus: response?.status,
                });
                clearTimeout(this.emailTimer);

                this.emailTimer = setTimeout(() => {
                    // console.log('emailTimer');
                    this.setState({
                        emailSaleSending: false,
                        emailSaleSendStatus: null,
                    });
                }, 2000);
            }
		}, drawerLevel + 1);
    }

    onClickPrintSale = e => {
        // console.log('onClickPrintSale', e);
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const receipt = <IntlProvider definition={languageDef}>
            <DyrejournalSaleReceipt stores={this.props.stores} />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'receipt';
        document.body.appendChild(printDiv);
        render(receipt, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    componentDidMount() {
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        this.loadSalePayments(newSale.id);
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const {
            sections,
            partialPayment,
            payDate,
            paymentInProgress,
            emailSaleSending,
            emailSaleSendStatus,
        } = this.state;
        const { userStore, saleStore } = this.props.stores;
        const { customer, user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            currentPayments = [],
        } = saleStore;

        const saleIsLocked = !!newSale.paymentMethod;
        const restPayment = saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, false), false, false);

        const { featureFlags = {} } = customer;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='dyrejournal.sale-invoice'>Sale Invoice</Text>
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>

                        <div class='d-flex flex-column mt-0 pt-2 pr-5' style='font-size: 1.5em;'>
                            <div class='d-flex flex-row justify-content-end'>
                                <div><Text id='dyrejournal.payment-total'>Total</Text></div>
                                <div class='ml-3 text-success font-weight-bold'>{util.format(saleTotal, 2, ',', ' ')}</div>
                            </div>
                            <div class='d-flex flex-row justify-content-end font-weight-lighter'>
                                <div><Text id='dyrejournal.payment-tax'>Tax</Text></div>
                                <div class='ml-3'>{saleTax}</div>
                            </div>
                            <div class='d-flex flex-row justify-content-end font-weight-bold text-danger'>
                                <div><Text id='dyrejournal.payment-rest'>Rest payment</Text></div>
                                <div class='ml-3'>{util.format(restPayment, 2)}</div>
                            </div>
                        </div>

                        {!newSale.id ? <>
                            <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                <div class='spinner-grow text-secondary' role='status'>
                                    <span class='sr-only'>Loading...</span>
                                </div>
                            </div>
                        </> : <>
                            {saleIsLocked ? <>
                                <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                    <button
                                        disabled={emailSaleSendStatus === 200}
                                        class={`btn btn-${emailSaleSendStatus === 200 ? 'success' : 'primary'} ml-2 rounded-pill`}
                                        onClick={this.onClickEmailReminder}
                                    >
                                        <i class={`fa-solid ${emailSaleSendStatus === 200 ? 'fa-check' : 'fa-paper-plane-top'} ${emailSaleSending ? 'fa-bounce': ''} mr-2`} />
                                        {['creditNote', 'invoiceCredited'].includes(newSale.paymentMethod) ? <>
                                            <Text id='dyrejournal.sale.send-invoice'>Send Invoice</Text>
                                        </> : <>
                                            <Text id='dyrejournal.email-reminder'>Email reminder</Text>
                                        </>}
                                    </button>

                                    <button
                                        class={`btn btn-info ml-2 rounded-pill`}
                                        onClick={this.onClickPrintSale}
                                    >
                                        {(newSale.status === 1 || newSale.status === 5 || restPayment === 0) ? <>
                                            <i class='fa-solid fa-print' /> <Text id='dyrejournal.print-receipt'>Print receipt</Text>
                                        </> : <>
                                            <i class='fa-solid fa-print' /> <Text id='dyrejournal.print-invoice'>Print invoice</Text>
                                        </>}
                                    </button>

                                </div>
                                <div class='w-100 d-flex flex-row justify-content-center mt-3 pb-3 border-bottom'>
                                    <button type='button' class='btn btn-warning rounded-pill ml-3' disabled={paymentInProgress} onClick={this.createCreditNoteSale} data-method='creditNote'>
                                        {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('creditNote')} /> <Text id='dyrejournal.sale-create-creditNote'>Create Credit Note</Text>
                                    </button>
                                </div>
                                <div class='w-100 d-flex flex-column align-items-center mt-4'>
                                    <div>
                                        <Text id='dyrejournal.sale-payment-method-already-choosen'>Payment method for sale already set</Text> (<i class={`${saleStore.getPaymentMethodIcon(newSale.paymentMethod)} mx-1`} /> {saleStore.getPaymentMethodText(newSale.paymentMethod)})
                                    </div>
                                    <button type='button' class='btn btn-link rounded-pill ml-3' onClick={this.unlockPaymentMethod}>
                                        <i class='fa-solid fa-lock' /> <Text id='dyrejournal.sale-unlock-payment-method'>Unlock payment method</Text>
                                    </button>
                                </div>
                            </> : <>
                                {partialPayment ? <></> : <>
                                    <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                        {!featureFlags.paymentHideInvoice && <>
                                            <button type='button' class='btn btn-warning rounded-pill ml-3' disabled={paymentInProgress} onClick={this.saveInvoice} data-method='invoice'>
                                                {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('invoice')} /> <Text id='dyrejournal.sale-save-invoice'>Save Invoice</Text>
                                            </button>
                                        </>}
                                        {/* <button type='button' class='btn btn-info rounded-pill ml-3' disabled={paymentInProgress} onClick={this.createCreditNote} data-method='creditNote'>
                                            {paymentInProgress && <><i class='fa-duotone fa-spinner-scale mr-2' /></>} <i class={saleStore.getPaymentMethodIcon('creditNote')} /> <Text id='dyrejournal.sale-create-creditNote'>Create Credit Note</Text>
                                        </button> */}
                                    </div>
                                </>}
                            </>}

                            {currentPayments && currentPayments.length > 0 && <>
                                <div class='font-weight-lighter px-3 box-header mt-5'>
                                    <Text id='dyrejournal.existing-sale-payments'>Existing Payments</Text>
                                </div>
                                <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                                    <div class='w-100 d-flex flex-column'>
                                        <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                            <small>
                                            <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                                <table class='table table-striped table-sm mb-0'>
                                                    <thead>
                                                        <tr>
                                                            <th class='text-muted text-left'><nobr><Text id='dyrejournal.partial-payment-date'>Date</Text></nobr></th>
                                                            <th class='text-muted text-center'><nobr><Text id='dyrejournal.partial-payment-method'>Method</Text></nobr></th>
                                                            <th class='text-muted text-right'><nobr><Text id='dyrejournal.sale.amount'>Amount</Text></nobr></th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPayments.map((e, idx) => <>
                                                            <tr data-id={e.id}>
                                                                <td>{util.formatDate(e.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</td>
                                                                <td class='text-center'><i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {e.paymentMethod}</td>
                                                                <td class='text-right'>{util.format(e.amount, 2, ',', ' ')}</td>
                                                                <td class='d-none d-sm-table-cell' onClick={this.onClickDeleteSalePayment} data-id={e.id}>
                                                                    <i class='fa-solid fa-trash text-danger' />
                                                                </td>
                                                            </tr>
                                                        </>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </small>
                                            {/* <xmp>{JSON.stringify(currentPayments, null, 4)}</xmp> */}
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {newSale.reminders && newSale.reminders.length > 0 && <>
                                <div class='w-100 d-flex flex-sm-row flex-column'>
                                    <div class='w-100 d-flex flex-column'>
                                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                                            <span><Text id='dyrejournal.payment-reminders'>Payment reminders</Text></span>
                                        </div>
                                        <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                            <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg border`}>
                                                <table class='table table-striped table-sm mb-0'>
                                                    <thead>
                                                        <tr>
                                                            <th class='text-muted text-left'><nobr><Text id='dyrejournal.reminder-date'>Date</Text></nobr></th>
                                                            <th class='text-muted text-center'><nobr><Text id='dyrejournal.reminder-method'>Method</Text></nobr></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newSale.reminders.map((e, idx) => <>
                                                            <tr>
                                                                <td>{util.formatDate(e.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</td>
                                                                <td class='text-center'><i class={saleStore.getReminderMethodIcon(e.type)} /> {e.type}</td>
                                                            </tr>
                                                        </>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <xmp>{JSON.stringify(newSale.partialPayments, null, 4)}</xmp> */}
                                        </div>
                                    </div>
                                </div>
                            </>}


                        </>}

                    </div>
                </div>
            </div>
        </>);
    }
}

export default SalePaymentInvoice;
