import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';

import ImageScroller from '../../components/imagescroller';

@observer
class DyrejournalSaleSearch extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            search: '',
            selectedIndex: 0,
        };
        this.mainContainer = null;
        this.searchTimer = null;
        this.linesContainer = null;
        this.lines = {};
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore } = this.props;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { holdingStore, searchResultList, query: inputQuery = {}, addData = [] } = this.props;
        const { saleStore, visitorStore, animalStore, productStore } = this.props.stores;

        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await holdingStore.load({ query: { ...inputQuery, search }, addData, skipUpdate: true });
            if (searchResultList === 'visitorResults') {
                saleStore.updateKeyValue(searchResultList, result);
            } else if (searchResultList === 'animalResults') {
                saleStore.updateKeyValue(searchResultList, result);
            } else if (searchResultList === 'productResults') {
                saleStore.updateKeyValue(searchResultList, result);
            } else if (searchResultList === 'searchResults') {
                holdingStore.updateKeyValue(searchResultList, result);
            }
        }
    }

    chooseLine = (e, idx) => {
        const { saleStore } = this.props.stores;
        const { searchResultList, append, callback = () => {}, holdingStore } = this.props;
        const { visitorResults, animalResults, productResults, newSale } = saleStore;
        const { searchResults } = holdingStore;

        let finalSearchResults = [];
        let newSaleObject = '';
        let newSaleArray = [];
        let selectedItem;
        if (searchResultList === 'visitorResults') {
            finalSearchResults = visitorResults || [];
            newSaleObject = 'visitors';
            newSaleArray = newSale.visitors || [];
            selectedItem = {
                ...finalSearchResults[idx],
                createdDate: new Date(),
                md5: md5(JSON.stringify({ ...finalSearchResults[idx], createdDate: new Date() })).toString(),
            };
        } else if (searchResultList === 'animalResults') {
            finalSearchResults = animalResults || [];
            newSaleObject = 'animals';
            newSaleArray = newSale.animals || [];
            selectedItem = {
                ...finalSearchResults[idx],
                createdDate: new Date(),
                md5: md5(JSON.stringify({ ...finalSearchResults[idx], createdDate: new Date() })).toString(),
            };
        } else if (searchResultList === 'productResults') {
            finalSearchResults = productResults || [];
            newSaleObject = 'products';
            newSaleArray = newSale.products || [];
            selectedItem = {
                ...finalSearchResults[idx],
                qty: 1,
                createdDate: new Date(),
                md5: md5(JSON.stringify({ ...finalSearchResults[idx], createdDate: new Date() })).toString(),
            };
        } else if (searchResultList === 'searchResults') {
            finalSearchResults = searchResults || [];
            newSaleObject = null;
            newSaleArray = null;
            selectedItem = {
                ...finalSearchResults[idx],
                createdDate: new Date(),
                qty: 1,
                md5: md5(JSON.stringify({ ...finalSearchResults[idx], createdDate: new Date() })).toString(),
            };
        }
        if (!finalSearchResults || !finalSearchResults[idx]) {
            return;
        }
        if (newSaleObject && newSaleArray) {
            if (append) {
                saleStore.updateObjectKeyValue('newSale', newSaleObject, [...newSaleArray, selectedItem]);
            } else {
                saleStore.updateObjectKeyValue('newSale', newSaleObject, [selectedItem]);
            }
        }
        callback(selectedItem);
    }

    handleKeyDown = e => {
        const { saleStore } = this.props.stores;
        const { searchResultList, holdingStore } = this.props;
        const { newSale, visitorResults, animalResults, productResults } = saleStore;
        const { searchResults } = holdingStore;
        let finalSearchResults = [];
        if (searchResultList === 'visitorResults') {
            finalSearchResults = visitorResults || [];
        } else if (searchResultList === 'animalResults') {
            finalSearchResults = animalResults || [];
        } else if (searchResultList === 'productResults') {
            finalSearchResults = productResults || [];
        } else if (searchResultList === 'searchResults') {
            finalSearchResults = searchResults || [];
        }

        const { selectedIndex } = this.state;
        if (e.keyCode === 38) {
            // Arrow up key pressed
            e.preventDefault();
            e.stopPropagation();
            // console.log('Arrow up key pressed');
            let idx = selectedIndex - 1;
            if (idx < 0) {
                idx = finalSearchResults.length - 1;
            }
            this.setState({
                selectedIndex: idx,
            });
            if (this.lines[idx]) {
                this.lines[idx].scrollIntoView({ behavior: 'smooth', block: 'center', container: this.linesContainer });
            }
        } else if (e.keyCode === 40) {
            // Arrow down key pressed
            e.preventDefault();
            e.stopPropagation();
            // console.log('Arrow down key pressed');
            let idx = selectedIndex + 1;
            if (idx >= finalSearchResults.length) {
                idx = 0;
            }
            this.setState({
                selectedIndex: idx,
            });
            if (this.lines[idx]) {
                this.lines[idx].scrollIntoView({ behavior: 'smooth', block: 'center', container: this.linesContainer });
            }
        } else if (e.keyCode === 13) {
            // Enter key pressed
            e.preventDefault();
            e.stopPropagation();
            // console.log('Enter key pressed');
            this.chooseLine(e, selectedIndex || 0);
            this.clearSearch();
        } else if (e.keyCode === 27) {
            // Esc key pressed
            e.preventDefault();
            e.stopPropagation();
            // console.log('Esc key pressed');
            this.clearSearch();
        }
    }

    clearSearch = () => {
        const { searchResultList, holdingStore } = this.props;
        const { saleStore } = this.props.stores;
        // Rest search and search results
        this.setState({
            selectedIndex: 0,
            search: '',
        });
        if (searchResultList === 'visitorResults') {
            saleStore.updateKeyValue(searchResultList, []);
        } else if (searchResultList === 'animalResults') {
            saleStore.updateKeyValue(searchResultList, []);
        } else if (searchResultList === 'productResults') {
            saleStore.updateKeyValue(searchResultList, []);
        } else if (searchResultList === 'searchResults') {
            holdingStore.updateKeyValue(searchResultList, []);
        }
    }

    clickLine = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { idx } = e.target.closest('.resultLine').dataset;
        this.chooseLine(e, idx);
        this.clearSearch();
    }

    render() {
        const {
            searchResultList,
            searchResultsLine = () => {},
            searchResultsFields = () => {},
            rightButton,
            rightButtonClick = () => {},
            disabled = false,
            holdingStore,
            rightButtonClassNames = 'btn-outline-secondary',
        } = this.props;

        const { saleStore, userStore } = this.props.stores;
        const {
            search,
            selectedIndex,
        } = this.state;
        const {
            title,
        } = this.props;

        const { newSale, visitorResults, animalResults, productResults } = saleStore;
        const { searchResults } = holdingStore;
        let finalSearchResults = [];
        if (searchResultList === 'visitorResults') {
            finalSearchResults = visitorResults;
        } else if (searchResultList === 'animalResults') {
            finalSearchResults = animalResults;
        } else if (searchResultList === 'productResults') {
            finalSearchResults = productResults;
        } else if (searchResultList === 'searchResults') {
            finalSearchResults = searchResults;
        }

        const { user = {} } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='d-flex flex-column justify-content-center px-3 mb-3 position-relative'>
                <div class='input-group'>
                    <div class='input-group-prepend'>
                        <span class='input-group-text rounded-pill-left'>
                            <i class='fa-regular fa-magnifying-glass'></i>
                        </span>
                    </div>
                    <input
                        disabled={disabled}
                        class={`form-control ${!search && !rightButton ? 'rounded-pill-right' : ''}`}
                        type='text'
                        value={search}
                        placeholder={`${title}`}
                        onInput={this.searchInput}
                        onKeyDown={this.handleKeyDown}
                        // onBlur={this.clearSearch}
                    />
                    {rightButton ? <>
                        {search && <div class='input-group-append'>
                            <button class='btn btn-danger' type='button' onClick={this.clearSearch}>
                                <i class='fa-solid fa-circle-xmark'></i>
                            </button>
                        </div>}
                        <div class='input-group-append'>
                            <button
                                // disabled={disabled}
                                class={`btn ${rightButtonClassNames} rounded-pill-right`}
                                type='button'
                                onClick={rightButtonClick}
                            >
                                <i class='fa-solid fa-plus' /> <span class='d-none d-md-inline-block'>{rightButton}</span>
                            </button>
                        </div>
                    </> : <>
                        {search && <div class='input-group-append'>
                            <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                <i class='fa-solid fa-circle-xmark'></i>
                            </button>
                        </div>}
                    </>}
                </div>


                {searchResultsFields && finalSearchResults.length ? <>
                    <div class={`d-flex flex-row justify-content-between w-100 `}>
                    <div class={`d-flex flex-row justify-content-between w-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} position-absolute`} style='z-index: 10000;'>
                        <div class='w-50 border rounded-lg py-1'>
                            <div class={`px-2 overflow-auto rounded-lg`} ref={r => this.linesContainer = r} style='max-height: 25vh;'>
                                {/* finalSearchResults:<xmp>{JSON.stringify(finalSearchResults, null, 4)}</xmp> */}
                                <table class='table table-sm table-striped table-hover'>
                                    <tbody>
                                        {finalSearchResults.map((res, idx) => {
                                            return (<>
                                                <tr>
                                                    <td
                                                        class='resultLine px-0 py-0'
                                                        onClick={this.clickLine}
                                                        data-idx={idx}
                                                        ref={r => this.lines[idx] = r}
                                                    >
                                                        {searchResultsLine(res, idx, selectedIndex)}
                                                    </td>
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class='w-50 px-3 py-3'>
                            {finalSearchResults[selectedIndex] && searchResultsFields(finalSearchResults[selectedIndex])}
                        </div>
                    </div>
                    </div>
                </> : <>
                    {(finalSearchResults && finalSearchResults.length > 0) && <>
                        <div
                            class='position-absolute w-100 overflow-auto px-5 '
                            style={`
                                height: ${finalSearchResults.length > 10 ? 400 : 200}px;
                                bottom: -${finalSearchResults.length > 10 ? 400 : 200}px;
                                left: 0px;
                                z-index: 10;
                            `}
                        >
                            <div class={`px-2 bg-light border box-container h-100 overflow-auto`} ref={r => this.linesContainer = r}>
                                {/* finalSearchResults:<xmp>{JSON.stringify(finalSearchResults, null, 4)}</xmp> */}
                                {finalSearchResults.map((res, idx) => {
                                    return (<>
                                        <div class='resultLine' onClick={this.clickLine} data-idx={idx} ref={r => this.lines[idx] = r}>
                                            {searchResultsLine(res, idx, selectedIndex)}
                                        </div>
                                    </>);
                                })}
                            </div>
                        </div>
                    </>}
                </>}


            </div>
        </>);
    }
}

export default DyrejournalSaleSearch;
