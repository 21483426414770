import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewProduct extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('product'),
        };
    }

    createProduct = async () => {
        const { callback = () => {} } = this.props;
        const { productStore } = this.props.stores;
        const { newProduct } = productStore;
        const response = await productStore.insert(newProduct);
        const product = response?.data?.product;
        productStore.updateKeyValue('newProduct', {});
        if (response.status === 201) {
            callback(product);
        }
    }

    componentDidMount() {
        const { id, isNew } = this.props;
        const { productStore } = this.props.stores;
        if (!isNew) {
            productStore.load(id, null, { addData: ['allProducts'] });
        }
    }

    render() {
        const { week, isNew, hideAdmin, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, productStore } = this.props.stores;
        const { user, isAdmin, isCustomerAdmin } = userStore;
        const { newProduct } = productStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        if (!isCustomerAdmin) {
            return (<>
                <div class='w-100 d-flex justify-content-center'>
                    <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='font-weight-lighter px-3 box-header mt-0'>
                                <Text id='product.noAccess'>You do not have access to this page.</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        }

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='product.new'>New product</Text> : <Text id='product.edit'>Edit product</Text>}
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={productStore}
                            objectName={isNew ? 'newProduct' : 'product'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            hideAdmin={hideAdmin}
                        />
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createProduct}>
                            {isNew ? <Text id='product.create'>Create product</Text> : <Text id='product.save'>Save product</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewProduct;
